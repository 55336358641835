.App {
  text-align: center;
}

.App-logo {
  width: 400px;
  max-width: 80vw;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #F01A46; */
  /* // background color should be a gradient from F01A46 to white */
  background: linear-gradient(0deg, #ec7b92 -100%, #FFFFFF 50%, #ec7b92 200%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
